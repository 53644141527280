.App {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(
        150deg,
        #6B00A1 12%,
        #263969
    );
  background-size: 400% 400%;
  animation:
      1s ease forwards container--start,
      container--animation 20s ease infinite;
  position: relative;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 24px;
}

#content {
  margin: auto;
  padding-bottom: 128px;
}

header {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

h1, h2 {
  margin: 0;
}

h2 {
  font-weight: 300;
}

#uris {
  display: flex;
  gap: 12px;
  padding: 12px;
  justify-content: center;
}

a {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px 8px;
  min-width: 120px;
  max-width: 240px;
  text-align: center;
  color: white;
  text-decoration: none;
  transition: opacity 0.2s;
}

a:hover {
  opacity: 0.8;
}

a:visited {
  all: unset;
}

img {
  width: 240px;
  text-align: center;
}

@keyframes container--animation {
  0% {
      background-position: 0% 50%;
  }

  50% {
      background-position: 100% 50%;
  }

  100% {
      background-position: 0% 50%;
  }
}